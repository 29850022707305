* {
  font-family: "Saira", sans-serif;
}
.glass {
  background: rgba(100, 100, 100, 0.2);
  backdrop-filter: blur(15px);
}
.translucent {
  background: rgba(0, 0, 0, 0.2);
}
#review-carousel .carousel-cell.is-selected .rounded {
  scale: 1.07;
  box-shadow: 0px 4px 40px rgba(53, 164, 223, 0.32);
}
.accordion-button.collapsed::after {
  filter: brightness(0) invert(100%);
}
.accordion-button.collapsed {
  color: white;
}
.shadow-primary {
  box-shadow: 0px 4px 40px rgba(244, 135, 31, 0.25);
}
.border-primary-light {
  border: 1px solid rgba(244, 135, 31, 0.3);
}
.flickity-slider {
  display: flex;
  align-items: center;
}
/* no circle */
.flickity-button {
  background: transparent;
}
.flickity-button:hover {
  background: transparent;
}

/* icon color */
.flickity-button-icon {
  fill: var(--bs-primary);
}
.flickity-prev-next-button.previous {
  left: -40px;
}
.flickity-prev-next-button.next {
  right: -40px;
}

.flickity-page-dots .dot {
  opacity: 0.2;
  background: var(--bs-primary);
}
/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  opacity: 0.7;
}
@media (min-width: 992px) {
  .shift-up {
    margin-top: -70px;
  }
  .pad-right {
    padding-right: 3rem !important;
  }
  .shift-down {
    margin-top: 70px;
  }
  .pad-left {
    padding-left: 3rem !important;
  }
  .col-20 {
    width: 20% !important;
  }
}
